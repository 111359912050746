const envConfig = {
  REACT_APP_API_TOKEN: process.env.REACT_APP_API_TOKEN ?? 'daddcd4202e044ef921b4e4fb43a8de7',
  REACT_APP_API_URL: process.env.REACT_APP_API_URL ?? 'https://antoninosapi.azure-api.net/webdev/',
  REACT_APP_MAPBOX_ACCESS_TOKEN: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? 'pk.eyJ1IjoiZ29wYW56YS1tYXBib3giLCJhIjoiY2tqeGFnZGJ6MDJncTJvazdqYzd6OHczYSJ9.9ymsPgBfRUtD8O_M8yyGEw',
  REACT_APP_GEO_API_KEY: process.env.REACT_APP_GEO_API_KEY ?? 'AIzaSyAKJ8Kgii2C4zquZmG0nqJ_lu8lSJ_VyII',
  REACT_APP_HUB_URL: process.env.REACT_APP_HUB_URL ?? 'https://antoninos-api-dev.azurewebsites.net/',
  UseLogRocket: process.env.REACT_APP_UseLogRocket ?? 'false',
  TosUrl: process.env.REACT_APP_TOS_URL ?? 'https://www.gopanza.com/legal/terms',
  FacebookPixelId: process.env.REACT_APP_FACEBOOK_PIXEL_ID ?? '710391307374860',
  ClaimsEmail: process.env.REACT_APP_CLAIMS_EMAIL ?? 'antoninospizza1988@gmail.com',

  ValidateUserEmail: process.env.REACT_APP_VALIDATE_USER_EMAIL ?? 'false',
  ValidateUserPhone: process.env.REACT_APP_VALIDATE_USER_PHONE ?? 'false'
};

export default envConfig;
